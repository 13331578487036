import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import thunk from "redux-thunk";

import {
  loginReducer
} from "./reducers/loginReducer";

import {
  propertyListReducer,
  featuredPropertyReducer,
  propertyReducer,
} from "./reducers/propertiesReducer";

import {
  featuredAgentsReducer,
  agentListReducer,
  agentReducer,
} from "./reducers/agentsReducer";
import { messagesReducer } from "./reducers/messagesReducer";

const middlewares = [thunk];

const reducer = combineReducers({
  propertyList: propertyListReducer,
  featuredProperty: featuredPropertyReducer,
  featuredAgents: featuredAgentsReducer,
  agentList: agentListReducer,
  agent: agentReducer,
  property: propertyReducer,
  messageList: messagesReducer,
  userData: loginReducer,
})
const intialState = {
  userData: {
    user: localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null
  }
};

const store = createStore(
  reducer,
  intialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
