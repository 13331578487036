import React, { useEffect, useRef } from "react";

import { Section, Add, Form } from "../components";
import { useParams } from "react-router-dom";
import {
  HeaderContainer,
  DashboardContainer,
  FooterContainer,
} from "../containers";
import {
  Descrition,
  Location,
  Image,
  Media,
  Details,
} from "../partials/add_property_partials";
import {
  addProperty
} from "../redux/actions/propertiesAction";
import { useDispatch } from "react-redux";
import FormData from 'form-data'
import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast('Please select all the details.');

const AddLisiting = () => {

  const dispatch = useDispatch();
  const [descriptionData, setDescriptiondata] = React.useState({})
  const [addressData, setAddressData] = React.useState({})
  const [detailsData, setDetailsData] = React.useState({})
  const [file, setSelectedFile] = React.useState([])
  const [link, setLink] = React.useState({})

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [HeaderContainer]);


  const { id } = useParams();

  const name = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (descriptionData.name == "" || descriptionData.price == "" || descriptionData.listed == "" || descriptionData.category == ""
      || descriptionData.configuration == "" || addressData.configuration == "" || addressData.address == "" ||
      addressData.state == "" || addressData.town == "" || addressData.district == "" || detailsData.room == "" ||
      detailsData.bedroom == "" || detailsData.bathroom == "" || detailsData.structreType == "" || detailsData.link == "") {
      notify()
    } else {
      let details = new FormData();
      for (const key of Object.keys(file)) {
        details.append('image', file[key])
      }
      details.append("name", descriptionData.name);
      details.append("price", descriptionData.price);
      details.append("listed", descriptionData.listed);
      details.append("description", descriptionData.description);
      details.append("category", descriptionData.category);
      details.append("buildupArea", addressData.configuration);
      details.append("propertyAddress", addressData.address);
      details.append("state", addressData.state);
      details.append("town", addressData.town);
      details.append("district", addressData.district);
      details.append("room", detailsData.room);
      details.append("bedroom", detailsData.bedroom);
      details.append("bathroom", detailsData.bathroom);
      details.append("structreType", detailsData.structreType);
      details.append("frame3d", link.link);
      dispatch(addProperty(details))
      e.target.reset();
    }
  }

  const getProperty = (data) => {
    setDescriptiondata(data)
  }

  const getAddress = (data) => {
    setAddressData(data)
  }

  const getDetails = (data) => {
    setDetailsData(data);
  }

  const getImage = (data) => {
    setSelectedFile(data.file);
  }

  const getFrameLink = (link) => {
    setLink(link);
  }

  return (
    <>
      <HeaderContainer bg={false} />
      <Section bgColor="--bs-fade-info">
        <Section.InnerContainer>
          <Toaster toastOptions={{
            className: '',
            style: {
              padding: '16px',
              color: 'red',
            },
          }} />
          <DashboardContainer title={id ? "Edit Property" : "Add Property"}>
            <Add>
              <Form onSubmit={handleSubmit} enctype="multipart/form-data">
                <Descrition getData={getProperty} />
                <Location getData={getAddress} />
                <Image getData={getFrameLink} />
                <Media getData={getImage} />
                <Details getData={getDetails} />
                <Add.Footer>
                  <Form.FormGroup class="form-group">
                    <Form.SubmitInput
                      type="submit"
                      value={id ? "Update Property" : "Submit Property"}
                    />
                  </Form.FormGroup>
                  {id && (
                    <Form.FormGroup class="form-group">
                      <Form.SubmitInput type="submit" value="Cancel Update" />
                    </Form.FormGroup>
                  )}
                </Add.Footer>
              </Form>
            </Add>
          </DashboardContainer>
        </Section.InnerContainer>
      </Section>
      <FooterContainer />
    </>
  );
};

export default AddLisiting;
