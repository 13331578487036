import React, { useState, useEffect } from "react";
import { Property } from "../components";
export const PropertGallery = ({ image }) => {
  return (
    <Property.Gallery>
      {
        image?.map((data, id) => {
          return (
            <Property.ImageContainer key={id}>
              <Property.Image source={data} />
            </Property.ImageContainer>
          )
        })
      }
    </Property.Gallery>
  );
};

export const PropertyFeatures = ({ features }) => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, []);

  const [featuresShown, setContentShown] = useState(true);

  // const contentHandler = () => {
  //   setContentShown((previousState) => !previousState);
  // };

  return (
    <Property.Info>
      <Property.InfoHeader>
        <Property.InfoTitle>Details and Features</Property.InfoTitle>
        <Property.Icon
          // name={featuresShown ? "fas fa-chevron-up" : "fas fa-chevron-down"}
          info></Property.Icon>
      </Property.InfoHeader>
      <Property.InfoContent contentShown={featuresShown}>
        <Property.InfoItem>
          <Property.Text>
            <Property.Span>Bedrooms : </Property.Span>
            {features.bedrooms}
          </Property.Text>
          <Property.Text>
            <Property.Span>Garage : </Property.Span>
            {features.garage}
          </Property.Text>
        </Property.InfoItem>

        <Property.InfoItem>
          <Property.Text>
            <Property.Span>Status : </Property.Span>
            {features.status ? "Active" : "Not Active"}
          </Property.Text>
          <Property.Text>
            <Property.Span>Elevator : </Property.Span>
            {features.elevator ? "Yes" : "No"}
          </Property.Text>
        </Property.InfoItem>
        <Property.InfoItem>
          <Property.Text>
            <Property.Span>Kitchen : </Property.Span>
            {features.kitchen ? "Availalbe" : "Not Available"}
          </Property.Text>
        </Property.InfoItem>
      </Property.InfoContent>
    </Property.Info>
  );
};

export const PropertyAmenities = ({ amenities }) => {
  const [amenitiesShown, setContentShown] = useState(true);

  // const contentHandler = () => {
  //   setContentShown((previousState) => !previousState);
  // };

  return (
    <Property.Info>
      <Property.InfoHeader>
        <Property.InfoTitle>Amenities</Property.InfoTitle>
        <Property.Icon
          // name={amenitiesShown ? "fas fa-chevron-up" : "fas fa-chevron-down"}
          info></Property.Icon>
      </Property.InfoHeader>
      <Property.InfoContent contentShown={amenitiesShown}>
        {amenities.map((amenity) => (
          <Property.InfoItem key={amenity}>
            <Property.Text>{amenity}</Property.Text>
          </Property.InfoItem>
        ))}
      </Property.InfoContent>
    </Property.Info>
  );
};

export const PropertyAddress = ({ address, state, town, district }) => {
  const [addressShown, setContentShown] = useState(true);

  // const contentHandler = () => {
  //   setContentShown((previousState) => !previousState);
  // };
  return (
    <Property.Info>
      <Property.InfoHeader>
        <Property.InfoTitle>Address</Property.InfoTitle>
        <Property.Icon
          // name={addressShown ? "fas fa-chevron-up" : "fas fa-chevron-down"}
          info></Property.Icon>
      </Property.InfoHeader>
      <Property.InfoContent contentShown={addressShown}>
        {/* <Property.InfoItem> */}
        <Property.Text>
          <Property.Span>Address : </Property.Span>
          {address}
        </Property.Text>

        <Property.Text>
          <Property.Span>State : </Property.Span>
          {state}
        </Property.Text>

        <Property.Text>
          <Property.Span>Town : </Property.Span>
          {town}
        </Property.Text>

        <Property.Text>
          <Property.Span>District : </Property.Span>
          {district}
        </Property.Text>
        {/* <Property.Text>
            <Property.Span>City : </Property.Span>
            {address.city}
          </Property.Text> */}
        {/* </Property.InfoItem> */}
        {/* <Property.InfoItem>
          <Property.Text>
            <Property.Span>County/Sub-County : </Property.Span>
            {address.county}
          </Property.Text>
          <Property.Text>
            <Property.Span>Street : </Property.Span>
            {address.street}
          </Property.Text>
        </Property.InfoItem>
        <Property.InfoItem>
          <Property.Text>
            <Property.Span>Area : </Property.Span>
            {address.area}
          </Property.Text>
        </Property.InfoItem> */}
      </Property.InfoContent>
    </Property.Info>
  );
};
export const PropertyDescription = ({ description, price }) => {
  const [descriptionShown, setContentShown] = useState(true);

  // const contentHandler = () => {
  //   setContentShown((previousState) => !previousState);
  // };
  return (
    <Property.Info>
      <Property.InfoHeader>
        <Property.InfoTitle>Property Description</Property.InfoTitle>
        <Property.Icon
          // name={descriptionShown ? "fas fa-chevron-up" : "fas fa-chevron-down"}
          info></Property.Icon>
        <Property.Text>
          <Property.Span>Price : </Property.Span>
          {price}
        </Property.Text>
      </Property.InfoHeader>
      {/* <Property.Text>
        <Property.Span>Price : </Property.Span>
        {price}
      </Property.Text> */}

      <Property.InfoContent block="true" contentShown={descriptionShown}>
        <Property.Text>{description}</Property.Text>
      </Property.InfoContent>
    </Property.Info>
  );
};

export const PropertyState = ({ state }) => {
  const [stateShown, setContentShown] = useState(true);

  return (
    <Property.Info>
      <Property.InfoHeader>
        <Property.InfoTitle>Property State</Property.InfoTitle>
        <Property.Icon
          info></Property.Icon>
      </Property.InfoHeader>
      <Property.InfoContent contentShown={stateShown}>
        <Property.Text>
          <Property.Span>State : </Property.Span>
          {state}
        </Property.Text>
      </Property.InfoContent>
    </Property.Info>
  );
};

export const PropertyTown = ({ town }) => {
  const [townShown, setContentShown] = useState(true);

  return (
    <Property.Info>
      <Property.InfoHeader>
        <Property.InfoTitle>Property Town</Property.InfoTitle>
        <Property.Icon
          info></Property.Icon>
      </Property.InfoHeader>
      <Property.InfoContent contentShown={townShown}>
        <Property.Text>
          <Property.Span>Town : </Property.Span>
          {town}
        </Property.Text>
      </Property.InfoContent>
    </Property.Info>
  );
};

export const PropertybuildupArea = ({ buildupArea, bedroom, bathroom, structreType }) => {
  const [buildupAreaShown, setContentShown] = useState(true);

  return (
    <Property.Info>
      <Property.InfoHeader>
        <Property.InfoTitle>Property Build Area</Property.InfoTitle>
        <Property.Icon
          info></Property.Icon>
      </Property.InfoHeader>
      <Property.InfoContent contentShown={buildupAreaShown}>
        <Property.Text>
          <Property.Span>BuildupArea : </Property.Span>
          {buildupArea}
        </Property.Text>

        <Property.Text>
          <Property.Span>Bedroom : </Property.Span>
          {bedroom}
        </Property.Text>

        <Property.Text>
          <Property.Span>Bathroom : </Property.Span>
          {bathroom}
        </Property.Text>

        <Property.Text>
          <Property.Span>StructreType : </Property.Span>
          {structreType}
        </Property.Text>
      </Property.InfoContent>
    </Property.Info>
  );
};