import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContactModal from "../containers/3Dimage";
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import {
  getProperty,
  getPropertyList,
} from "../redux/actions/propertiesAction";
import { Section, Property } from "../components";
import {
  HeaderContainer,
  ContactAgentContainer,
  PropertyRelatedContainer,
  FooterContainer,
} from "../containers";
import { useHistory } from "react-router-dom";
import {
  PropertGallery,
  PropertyAddress,
  PropertyAmenities,
  PropertyFeatures,
  PropertyDescription,
  PropertyState,
  PropertyTown,
  PropertybuildupArea,
} from "../partials/property_features_partial";

const Listing = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();

  const singleProperty = useSelector((state) => state.property);
  const propertyList = useSelector((state) => state.propertyList);

  const { property } = singleProperty;

  useEffect(() => {
    dispatch(getProperty(id));
    dispatch(getPropertyList());
  }, [dispatch, id]);

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <HeaderContainer bg="false" />
      <Section bgColor="--bs-fade-info">
        <Section.InnerContainer>
          <Property.Header>
            <Property.HeaderLeft>
              <Property.Title>{property.name}</Property.Title>
              <Property.Location>
                {/* <Property.Icon name="fas fa-map-marker-alt"></Property.Icon> */}
                <Property.Text>{property.location}</Property.Text>
              </Property.Location>
            </Property.HeaderLeft>
            <Property.HeaderRight>
              <Property.Title>
                Rs {"   "}
                {property.price}
                <Property.Span>
                  {property.type === "rental" ? "/ Month" : ""}
                </Property.Span>
              </Property.Title>
            </Property.HeaderRight>
          </Property.Header>
          <div className="text-right mb-3" style={{ float: "right", marginRight: "33%" }}>
            <Button style={{ backgroundColor: "#25408f" }} className="ml-auto" onClick={() => { window.open(`/image3d/${id}`, '_blank'); }}>3-D View<i class="mx-2 fa fa-cube"></i></Button>
            {/* <ContactModal show={modalShow}
              onHide={() => setModalShow(false)} /> */}
          </div>
          <Property.Content>
            <Property.Left>

              <PropertGallery image={property.image} />
              {/* <PropertyFeatures features={property.features} /> */}
              {/* <PropertyAmenities amenities={property.amenities} /> */}
              <PropertyAddress address={property.propertyAddress} state={property.state} town={property.town} district={property.district} />
              <PropertybuildupArea buildupArea={property.buildupArea} bedroom={property.bedroom} bathroom={property.bathroom} structreType={property.structreType} />
              <PropertyDescription description={property.description} price={property.price} />
              {/* <PropertyState state={property.state} />
              <PropertyTown town={property.town} /> */}
            </Property.Left>
            <Property.Right>
              <ContactAgentContainer property={property} />
              <PropertyRelatedContainer
                property={property}
                featured={propertyList.properties}
              />
            </Property.Right>
          </Property.Content>
        </Section.InnerContainer>
      </Section>
      <FooterContainer />
    </>
  );
};

export default Listing;
