import React from "react";
import { Property, Form } from "../components";
import toast, { Toaster } from 'react-hot-toast';
import Axios from "axios";
import { URL } from "../redux/constants/mainConstant";

const notify = () => toast('Mail sented sucessfully.');

const ContactAgentContainer = ({ property, closeModal }) => {

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [description, setDescription] = React.useState("")

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      email: email,
      phone: phone,
      description: description
    }
    Axios.post(URL + 'api/send-mail', data).then((res) => {
      if (res.status == 200) {
        notify()
      }
      console.log("res mail", res);
    })
  }

  const phoneDecChange = (e, property) => {
    setPhone(e.target.value)
    setDescription(`i would like to know about ${property.name} , ${property.buildupArea}, RS ${property.price} in ${property.state}, ${property.town}`)
  }


  return (
    <>
      <Property.Contact>
        <Property.ContactHeader>
          <Property.ContactItem>
            <Property.AgentImage
              source={
                property.userData ? property.userData.image[0] : null
              }
            />
          </Property.ContactItem>
          <Property.ContactItem>
            <Property.Subtitle>{property.userData?.name}</Property.Subtitle>
            <Property.ContactList>
              <Property.ListItem>
                <Property.Icon name="fas fa-phone-alt"></Property.Icon>
                <Property.Text><a href="tel:254720843306" style={{ color: "#ffff" }}>{property.userData?.phone}</a></Property.Text>
              </Property.ListItem>
            </Property.ContactList>
          </Property.ContactItem>
        </Property.ContactHeader>
        <Property.ContactContent>
          <Toaster toastOptions={{
            className: '',
            style: {
              padding: '16px',
              color: 'green',
            },
          }} />
          <Property.ContactContainer>
            <Form onSubmit={handleSubmit}>
              <Form.FormGroup>
                <Form.Input type="text" placeholder="Name" onChange={(e) => { setName(e.target.value) }} required />
              </Form.FormGroup>
              <Form.FormGroup>
                <Form.Input type="text" placeholder="Email" onChange={(e) => { setEmail(e.target.value) }} required />
              </Form.FormGroup>

              <Form.FormGroup>
                <Form.Input type="text" placeholder="Phone Number" onChange={(e) => { phoneDecChange(e, property) }} required />
              </Form.FormGroup>
              <Form.FormGroup>
                <Form.TextArea
                  placeholder="I would love to know more about this property"
                  name=""
                  id=""
                  cols="24"
                  defaultValue={`i would like to know about ${property.name} , ${property.buildupArea}, RS ${property.price} in ${property.state}, ${property.town}`}
                  rows="8" required></Form.TextArea>
              </Form.FormGroup>
              <Form.FormGroup>
                <Form.SubmitInput type="submit" value="Send Message" />
              </Form.FormGroup>
            </Form>
          </Property.ContactContainer>
        </Property.ContactContent>
      </Property.Contact>
    </>
  );
};

export default ContactAgentContainer;
