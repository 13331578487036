import {
  GET_PROPERTIES,
  GET_FEATURED_PROPERTIES,
  GET_PROPERTY,
  ADD_PROPERTY,
  PROPERTY_SEARCH,
  PROPERTY_FILTER
} from "../constants/propertyConstants";
import { URL } from "../constants/mainConstant"
import Axios from "axios"
import properties from "../../dev-data/listing";
import swal from 'sweetalert';

export const getPropertyList = () => async (dispatch) => {
  try {
    const { data } = await Axios.get(URL + `api/property`);
    console.log("data", data);
    dispatch({ type: GET_PROPERTIES, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const getProperty = (id) => async (dispatch,) => {
  //console.log("id in action", id);
  try {
    const { data } = await Axios.get(URL + `api/property/${id}`);
    dispatch({ type: GET_PROPERTY, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const addProperty = (details) => async (dispatch, getState) => {
  try {
    const { userData: { user } } = getState();
    console.log("user", user);
    const { data } = await Axios.post(URL + `api/add-property`, details, {
      headers: {
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Authorization': `Bearer ${user.token}`,
        'Content-Type': `multipart/form-data`,
      }
    });
    console.log("data after post request", data);
    swal("", `${data.message}`, "success");
    dispatch({ type: ADD_PROPERTY, payload: data });

  } catch (error) {
    console.log(error.message);
  }
};


export const propertySearch = (details) => async (dispatch) => {
  try {
    const { data } = await Axios.post(URL + `api/search`, details);
    dispatch({ type: PROPERTY_SEARCH, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const propertyPriceFilter = (data) => async (dispatch) => {
  console.log("filter price", data)
  try {
    dispatch({ type: PROPERTY_FILTER, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const getFeaturedList = () => async (dispatch) => {
  const featured = properties
    .filter((property) => property.featured === true)
    .slice(0, 6);
  dispatch({ type: GET_FEATURED_PROPERTIES, payload: featured });
};

