import {
  GET_PROPERTIES,
  GET_FEATURED_PROPERTIES,
  GET_PROPERTY,
  PROPERTY_SEARCH,
  PROPERTY_FILTER
} from "../constants/propertyConstants";

export const propertyListReducer = (state = { properties: [], loading: true }, action) => {
  switch (action.type) {
    case GET_PROPERTIES:
      return { properties: action.payload, loading: false };
    case PROPERTY_SEARCH:
      return { properties: action.payload, loading: false };
    case PROPERTY_FILTER:
      return { properties: state.properties.filter((data) => data.price <= action.payload) }
    // console.log("filter price", state.properties.filter((data) => data.price <= action.payload));
    default:
      return state;
  }
};

export const featuredPropertyReducer = (state = { featured: [] }, action) => {
  switch (action.type) {
    case GET_FEATURED_PROPERTIES:
      return { featured: action.payload };
    default:
      return state;
  }
};

export const propertyReducer = (state = { property: {} }, action) => {
  switch (action.type) {
    case GET_PROPERTY:
      return { property: action.payload };
    default:
      return state;
  }
};
