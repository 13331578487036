const links = [
  {
    name: "Home",
    to: "/",
  },
  {
    name: "Listing",
    to: "/listing",
  },
  {
    name: "Agents",
    to: "/agents",
  },
  // {
  //   name: "logout",
  //   to: "/logout",
  // },
  // {
  //   name: "login",
  //   to: "/login",
  // },
];

export default links;
