import React, { useState } from "react";
import { Listing } from "../components";
import ContactModal from "./ContactModal";

const ListingItemContainer = ({ featured, width }) => {

  const [modalShow, setModalShow] = React.useState(false);

  const contactModal = () => {
    setModalShow(true)
  }

  return (
    <Listing width={width}>
    <Listing.Top>
        <Listing.TopItem>
          <Listing.Image source={`${featured.image[0]}`} />
          <Listing.TopItemContainer>
            <Listing.TopItemInfo>
              <Listing.Icon></Listing.Icon>
              <Listing.Text location>{featured.location}</Listing.Text>
            </Listing.TopItemInfo>
          </Listing.TopItemContainer>
        </Listing.TopItem>
      </Listing.Top>
      <Listing.Bottom>
        <Listing.BottomItem>
          <Listing.Title>
            <Listing.Anchor to={`/property/${featured._id}`}>
              {featured.title}
            </Listing.Anchor>
          </Listing.Title>
          <Listing.Price>Rs {featured.price}</Listing.Price>
          <p className="propertyDetails"><span className="propertyBold">{featured.buildupArea} flat</span> for {featured.listed} in {featured.town}</p>
          <Listing.Text description>
            {featured.description.substring(0, 100)}
          </Listing.Text>
          <Listing.Button>
            <Listing.Anchor to={`/property/${featured._id}`}>
              Details
            </Listing.Anchor>
          </Listing.Button>
          <Listing.Button onClick={contactModal}>
            <Listing.Anchor>
              Contact US
            </Listing.Anchor>
          </Listing.Button>
          <ContactModal show={modalShow} property={featured}
            onHide={() => setModalShow(false)} />
          <Listing.AgentContainer>
            {/* <Listing.AgentImageContainer>
              <Listing.Image
                src={`/images/agents/${featured.image}`}
                profile="true"
              />
            </Listing.AgentImageContainer> */}
          </Listing.AgentContainer>
        </Listing.BottomItem>
      </Listing.Bottom>
    </Listing>

  );
};

export default ListingItemContainer;
