import React from "react";
import { Agents } from "../components";

const AgentItemContainer = ({ agent }) => {
  return (
    <Agents>
      <Agents.Top>
        <Agents.Wrapper>
          <Agents.Image src={`${agent.image[0]}`} alt="image" />
        </Agents.Wrapper>
      </Agents.Top>
      <Agents.Bottom>
        <Agents.Wrapper>
          <Agents.Info>
            <Agents.InfoDiv>
              <Agents.Title>
                <Agents.Anchor to={`agent/${agent.id}`}>
                  {agent.name}
                </Agents.Anchor>
              </Agents.Title>
              <Agents.Text count>
                {agent.propertyCount ? agent.propertyCount : 0} Properties
              </Agents.Text>
            </Agents.InfoDiv>
            <Agents.InfoDiv icon>
              <Agents.Icon></Agents.Icon>
            </Agents.InfoDiv>
          </Agents.Info>
          <Agents.Description>
            <Agents.Text >{agent.description?.substring(0, 100)}</Agents.Text>
          </Agents.Description>
          <Agents.CallInfo>
            <Agents.Span>Call : </Agents.Span> {agent.phone}
          </Agents.CallInfo>
          {/* <Agents.MoreInfo>
            <Agents.Anchor to={`agent/${agent._id}`}>View Profile</Agents.Anchor>
          </Agents.MoreInfo> */}
        </Agents.Wrapper>
      </Agents.Bottom>
    </Agents>
  );
};

export default AgentItemContainer;
