import React, { useRef, useState } from "react";
import { Add, Form } from "../components";

const Descrition = (props) => {

  const [name, setName] = useState("")
  const [price, setPrice] = useState("")
  const [category, setCategory] = useState("")
  const [listed, setListed] = useState("")
  const [description, setDescription] = useState("")

  React.useEffect(() => {
    const data = {
      name: name,
      price: price,
      category: category,
      listed: listed,
      description: description
    }
    props.getData(data);
  }, [name, price, category, listed, description])

  return (
    <Add.Description>
      <Add.DescriptionHeader>
        <Add.Title>Description</Add.Title>
      </Add.DescriptionHeader>
      <Add.DescriptionContent>
        <Add.DescriptionContentTop>
          <Form.FormGroup useRef={name} onChange={(e) => {
            setName(e.target.value)
          }}>
            <Form.Label>
              Property Title <span>(required)</span>
            </Form.Label>
            <Form.Input />
          </Form.FormGroup>
          <Form.FormGroup onChange={(e) => {
            setPrice(e.target.value)
          }}>
            <Form.Label>
              Property Price <span>(required)</span>
            </Form.Label>
            <Form.Input type="text" />
          </Form.FormGroup>
          <Form.FormGroup onChange={(e) => {

            setCategory(e.target.value)
          }}>
            <Form.Label>
              Category <span>(required)</span>
            </Form.Label>
            <Form.Select name="category">
              <Form.Option defaultValue>
                None
              </Form.Option>
              <Form.Option>Apartment</Form.Option>
              <Form.Option>House</Form.Option>
              <Form.Option>Land</Form.Option>
            </Form.Select>
          </Form.FormGroup>
          <Form.FormGroup onChange={(e) => {

            setListed(e.target.value)
          }}>
            <Form.Label>
              Listed In <span>(required)</span>
            </Form.Label>
            <Form.Select name="none" id="">
              <Form.Option defaultValue>
                None
              </Form.Option>
              <Form.Option>Rental</Form.Option>
              <Form.Option>Sales</Form.Option>
            </Form.Select>
          </Form.FormGroup>
        </Add.DescriptionContentTop>
        <Add.DescriptionContentBottom>
          <Form.FormGroup onChange={(e) => {
            setDescription(e.target.value)
          }}>
            <Form.Label>
              Description <span>(required)</span>
            </Form.Label>
            <Form.TextArea name="" id="" cols="30" rows="10"></Form.TextArea>
          </Form.FormGroup>
        </Add.DescriptionContentBottom>
      </Add.DescriptionContent>
    </Add.Description>
  );
};

const Location = (props) => {
  const [frame3d, setFrame3d] = useState("")
  const [address, setAddress] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [town, setTown] = useState("")
  const [configuration, setConfiguration] = useState("")


  React.useEffect(() => {
    const data = {
      address: address,
      state: state,
      city: city,
      town: town,
      configuration: configuration
    }
    props.getData(data);
  }, [address, state, city, town, configuration])

  return (
    <Add.Location>
      <Add.LocationHeader>
        <Add.Title>Property Location</Add.Title>
      </Add.LocationHeader>
      <Add.LocationContent>
        <Add.LocationContentTop>
          <Form.FormGroup onChange={(e) => {
            setAddress(e.target.value)
          }}>
            <Form.Label>
              Address <span>(required)</span>
            </Form.Label>
            <Form.Input type="text" />
          </Form.FormGroup>
        </Add.LocationContentTop>
        <Add.LocationContentBottom>
          <Form.FormGroup onChange={(e) => {
            setState(e.target.value)
          }}>
            <Form.Label>
              State<span>(required)</span>
            </Form.Label>
            <Form.Input type="text" />
          </Form.FormGroup>
          <Form.FormGroup onChange={(e) => {
            setCity(e.target.value)
          }}>
            <Form.Label>
              City<span>(required)</span>
            </Form.Label>
            <Form.Input type="text" />
          </Form.FormGroup>
          <Form.FormGroup onChange={(e) => {
            setTown(e.target.value)
          }}>
            <Form.Label>
              Town
            </Form.Label>
            <Form.Input />
          </Form.FormGroup>
          <Form.FormGroup onChange={(e) => {
            setConfiguration(e.target.value)
          }}>
            <Form.Label>
              Configuration<span>(3BHK, etc..)</span>
            </Form.Label>
            <Form.Input type="text" />
          </Form.FormGroup>
        </Add.LocationContentBottom>
      </Add.LocationContent>
    </Add.Location>
  );
};

const Image = (props) => {

  const [link, setLink] = React.useState("")

  React.useEffect(() => {
    const data = {
      link: link
    }
    props.getData(data);
  }, [link])


  return (
    <Add.Description>
      <Add.DescriptionHeader>
        <Add.Title>Property 3-D Image</Add.Title>
      </Add.DescriptionHeader>
      <Add.DescriptionContent>
        <Add.DescriptionContentTop>
          <Form.FormGroup onChange={(e) => {
            setLink(e.target.value)
          }}>
            <Form.Label>
              3-D Image <span>(required)</span>
            </Form.Label>
            <Form.Input />
          </Form.FormGroup>
        </Add.DescriptionContentTop>
      </Add.DescriptionContent>
    </Add.Description>
  );
};

const Media = (props) => {
  const hiddenFileInput = useRef(null);
  const [file, setSelectedFile] = React.useState(null)

  React.useEffect(() => {
    const data = {
      file: file
    }
    props.getData(data);
  }, [file])

  const handleFileButton = (e) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  return (
    <Add.Media>
      <Add.MediaHeader>
        <Add.Title>Property Images</Add.Title>
      </Add.MediaHeader>
      <Add.MediaContent>
        <Form.FormGroup>
          <Form.Label>Images</Form.Label>
          {/* Special input file case */}
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={(e) => setSelectedFile(e.target.files)}
            style={{ display: "none" }}
            multiple
          />
          <Add.Button onClick={handleFileButton}>Upload Files</Add.Button>
        </Form.FormGroup>
      </Add.MediaContent>
    </Add.Media>
  );
};

const Details = (props) => {
  const [room, setRoom] = useState("")
  const [bedroom, setBedroom] = useState("")
  const [bathroom, setBathroom] = useState("")
  const [structreType, setStructreType] = useState("")


  React.useEffect(() => {
    const data = {
      room: room,
      bedroom: bedroom,
      bathroom: bathroom,
      structreType: structreType
    }
    props.getData(data);
  }, [room, bedroom, bathroom, structreType])

  return (
    <Add.Details>
      <Add.DetailsHeader>
        <Add.Title>Property Details</Add.Title>
      </Add.DetailsHeader>
      <Add.DetailsContent>
        <Form.FormGroup onChange={(e) => {
          console.log(e.target.value)
          setRoom(e.target.value)
        }}>
          <Form.Label>Rooms</Form.Label>
          <Form.Input type="text" />
        </Form.FormGroup>
        <Form.FormGroup onChange={(e) => {
          setBedroom(e.target.value)
        }}>
          <Form.Label>Bedrooms</Form.Label>
          <Form.Input type="text" />
        </Form.FormGroup>
        <Form.FormGroup onChange={(e) => {
          setBathroom(e.target.value)
        }}>
          <Form.Label>Bathrooms</Form.Label>
          <Form.Input type="text" />
        </Form.FormGroup>
        <Form.FormGroup onChange={(e) => {
          console.log("structre type", e.target.value);
          setStructreType(e.target.value)
        }}>
          <Form.Label>Structure Type</Form.Label>
          <Form.Select name="none" id="" class="form-select">
            <Form.Option defaultValue>
              Not Available
            </Form.Option>
            <Form.Option>Brick</Form.Option>
            <Form.Option>Wood</Form.Option>
            <Form.Option>Cement</Form.Option>
          </Form.Select>
        </Form.FormGroup>
      </Add.DetailsContent>
    </Add.Details>
  );
};

export { Descrition, Location, Media, Details, Image };
