export const GET_PROPERTIES = "GET_PROPERTIES";

export const GET_FEATURED_PROPERTIES = "GET_FEATURED_PROPERTIES";

export const GET_PROPERTY = "GET_PROPERTY";

export const ADD_PROPERTY = "ADD_PROPERTY";

export const PROPERTY_SEARCH = "PROPERTY_SEARCH";

export const PROPERTY_FILTER = "PROPERTY_FILTER"