import React, { useEffect } from "react";
import {
    HeaderContainer,
    FeaturedListingContainer,
    FeaturedAgentsContainer,
    HomeContactContainer,
    FooterContainer,
} from "../containers";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProperty } from "../redux/actions/propertiesAction";

const Image3D = () => {

    const { id } = useParams();
    const dispatch = useDispatch();

    const singleProperty = useSelector((state) => state.property);

    const { property } = singleProperty;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [HeaderContainer]);

    useEffect(() => {
        dispatch(getProperty(id));
    }, [])

    return (
        <>
            <HeaderContainer bg="false" />
            <div className="row">
                <iframe src={property.frame3d} height="1000" width="770" frameborder="0"></iframe>
            </div>
            {/* <FooterContainer /> */}
        </>
    );
};

export default Image3D;
