import React from 'react';

function MessageBox(props) {
    return (
        <div className='container'>
            <div className={`alert alert-danger`}>
                {props.message}
            </div>
        </div>
    );
}

export default MessageBox;
