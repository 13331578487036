import React, { useState, useEffect, useRef } from "react";
import {
  HeaderContainer,
  DashboardContainer,
  FooterContainer,
} from "../containers";
import { Section, AdminAgents, Table } from "../components";
import { addAgents, getAgentList } from "../redux/actions/agentsAction";
import { useDispatch, useSelector } from "react-redux";
import { Signup, Form } from "../components";
import toast, { Toaster } from 'react-hot-toast';
import { Fragment } from "react";

const notify = () => toast('Mismatch in password.');

const AgentAdding = () => {
  const userName = useRef(null);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [description, setDescription] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [file, setSelectedFile] = useState([])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      notify()
    } else {
      let details = new FormData();
      for (const key of Object.keys(file)) {
        details.append('image', file[key])
      }
      details.append("name", name);
      details.append("email", email);
      details.append("password", password);
      details.append("phone", phone);
      details.append("description", description);
      dispatch(addAgents(details))
      e.preventDefault();
      e.target.reset();
    }
  }

  return (
    <>
      <HeaderContainer bg={false} />
      <Section bgColor="--bs-fade-info">
        <Section.InnerContainer>
          <DashboardContainer title="All Agents">
            <AdminAgents>
              <AdminAgents.Content>
                <Toaster toastOptions={{
                  className: '',
                  style: {
                    padding: '16px',
                    color: 'red',
                  },
                }} />
                <div className="container">
                  <div className="col-md-12">
                    <Form useRef={userName} onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.FormGroup onChange={(e) => { setName(e.target.value) }}>
                            <Form.Label>Name</Form.Label>
                            <Form.Input type="text" required />
                          </Form.FormGroup>
                        </div>
                        <div className="col-md-6">
                          <Form.FormGroup onChange={(e) => { setPhone(e.target.value) }}>
                            <Form.Label>Phone</Form.Label>
                            <Form.Input type="text" required />
                          </Form.FormGroup>
                        </div>
                        <div className="col-md-6">
                          <Form.FormGroup onChange={(e) => { setEmail(e.target.value) }}>
                            <Form.Label>Email</Form.Label>
                            <Form.Input type="email" required />
                          </Form.FormGroup>
                        </div>
                        <div className="col-md-6">
                          <Form.FormGroup>
                            <Form.Label>Select Image</Form.Label>
                            <input class="form-control" onChange={(e) => setSelectedFile(e.target.files)} type="file" id="formFileMultiple" required multiple />
                          </Form.FormGroup>
                        </div>
                        <div className="col-md-12">
                          <Form.FormGroup onChange={(e) => { setDescription(e.target.value) }}>
                            <Form.Label>Description</Form.Label>
                            <Form.TextArea name="" id="" cols="10" rows="3"></Form.TextArea>
                          </Form.FormGroup>
                        </div>
                        <Form.FormGroup onChange={(e) => { setPassword(e.target.value) }}>
                          <Form.Label>Password</Form.Label>
                          <Form.Input type="password" required />
                        </Form.FormGroup>
                        <Form.FormGroup onChange={(e) => { setConfirmPassword(e.target.value) }}>
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Input type="password" required />
                        </Form.FormGroup>
                        <Form.FormGroup>
                          <Form.SubmitInput type="submit" value="Signup" />
                        </Form.FormGroup>
                      </div>
                    </Form>
                  </div>
                </div>
              </AdminAgents.Content>
            </AdminAgents>
          </DashboardContainer>
        </Section.InnerContainer>
      </Section>
      <FooterContainer />
    </>

  );
};

export default AgentAdding;
