import React from 'react';

function LoadingBox() {
    return (
        <div className='container'>
            <div>
                <li className="fa fa-spinner fa-spin"></li>Loading...
            </div>
        </div>
    );
}

export default LoadingBox;