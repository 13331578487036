import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Section } from "../components";
import { ListingItemContainer } from "./index";
import { getFeaturedList, getPropertyList } from "../redux/actions/propertiesAction";
import { useHistory } from "react-router-dom";
import LoadingBox from "../helpers/LoadingBox";
import MessageBox from "../helpers/MessageBox";

const FeaturedListingContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const propertyList = useSelector((state) => state.propertyList);

  console.log("propertydList", propertyList);

  //const { featured: properties } = propertydList;

  useEffect(() => {
    dispatch(getPropertyList());
  }, []);

  return (
    <Section bgColor="--bs-light">
      {
        propertyList.loading ? <LoadingBox /> :
          <Section.InnerContainer>
            <Section.Header>
              <Section.Title>Properties</Section.Title>
            </Section.Header>
            {
              propertyList?.properties.length === 0 ? <MessageBox message="No property found" /> :
                <Section.Content>
                  {propertyList?.properties.map((featured) => (
                    <ListingItemContainer key={featured.id} featured={featured} />
                  ))}
                </Section.Content>
            }
            {
              propertyList?.properties.length === 0 ? null : <Section.Footer>
                <Section.Button onClick={() => {
                  history.push("/listing")
                }}>More Listing</Section.Button>
              </Section.Footer>
            }
          </Section.InnerContainer>
      }

    </Section>
  );
};

export default FeaturedListingContainer;
