import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MessageBox from "../helpers/MessageBox";
import {
  HeaderContainer,
  AgentItemContainer,
  FooterContainer,
} from "../containers";
import { getAgentList } from "../redux/actions/agentsAction";
import { Section } from "../components";

const Agents = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [HeaderContainer]);

  const dispatch = useDispatch();

  const agents = useSelector((state) => state.agentList);

  const agentList = useSelector((state) => state.agentList);

  const { agents: agentsList } = agents;

  useEffect(() => {
    dispatch(getAgentList());
  }, [dispatch]);
  return (
    <>
      <HeaderContainer bg={false} />
      <Section bgColor="--bs-fade-info">
        <Section.InnerContainer>
          <Section.Header>
            <Section.Title>Our Agents</Section.Title>
            <Section.Text>List of our most trusted agents</Section.Text>
          </Section.Header>
          {
            agentList?.agents.length === 0 ? <MessageBox message="No Agents found" /> :
            <Section.Content>
            {agentsList.map((agent) => (
              <AgentItemContainer agent={agent} />
            ))}
          </Section.Content>
          }
          
        </Section.InnerContainer>
      </Section>
      <FooterContainer />
    </>
  );
};

export default Agents;
