import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { propertySearch, propertyPriceFilter } from "../redux/actions/propertiesAction";
import { FormWrapper, Form } from "../components";
import { priceFormat } from "../helpers/helper_functions";
import Axios from "axios";
import { URL } from "../redux/constants/mainConstant";
import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast('Please select the details.');

const AdvancedSearchContainer = () => {
  const dispatch = useDispatch();

  const [state, setState] = React.useState([])
  const [type, setType] = React.useState("")
  const [category, setCategory] = React.useState("")
  const [stateData, setstateData] = React.useState("")
  const [room, setRoom] = React.useState("")
  const [Search, setSearch] = React.useState("")

  const { properties } = useSelector((state) => state.propertyList);

  // const price = properties.map(
  //   (property) => +property.price
  // );

  // const maxPrice = Math.max.apply(null, price),
  //   minPrice = Math.min.apply(null, price);


  const [priceRange, setPriceRange] = useState(0);

  const init = () => {
    Axios.get(URL + 'api/state').then((res) => {
      setState(res.data);
    })
  }

  const priceFilter = (value) => {
    setPriceRange(value)
    dispatch(propertyPriceFilter(value))
  }

  useEffect(() => {
    init();
  }, [dispatch]);

  const search = (e) => {
    e.preventDefault();
    if (type == "" || category == "" || state == "" || room == "") {
      //alert(1)
      notify()
    } else {
      const data = {
        type: type,
        category: category,
        state: stateData,
        rooms: room,
        search: Search,
      }
      dispatch(propertySearch(data))
    }

  }

  const typeSearch = (e) => {
    console.log(e.target.value);
  }

  return (
    <FormWrapper>
      <FormWrapper.Header>
        <FormWrapper.Title>Advanced Search</FormWrapper.Title>
      </FormWrapper.Header>
      <FormWrapper.Content>
        <Form>
          <Form.FormGroup>
            <Form.Select onChange={(e) => {
              setType(e.target.value)
            }}>
              <Form.Option defaultValue>Types</Form.Option>
              <Form.Option >Rental</Form.Option>
              <Form.Option>Sales</Form.Option>
            </Form.Select>
          </Form.FormGroup>
          <Form.FormGroup>
            <Form.Select onChange={(e) => {
              setstateData(e.target.value)
            }}>
              <Form.Option defaultValue>State</Form.Option>
              {state.map((data, id) => (
                <Form.Option key={id}>{data.name}</Form.Option>
              ))}
            </Form.Select>
          </Form.FormGroup>
          <Form.FormGroup>
            <Form.Select onChange={(e) => {
              setCategory(e.target.value)
            }}>
              <Form.Option defaultValue>Categories</Form.Option>
              <Form.Option >House</Form.Option>
              <Form.Option >Apartment</Form.Option>
            </Form.Select>
          </Form.FormGroup>
          <Form.FormGroup>
            <Form.Select onChange={(e) => {
              setRoom(e.target.value)
            }}>
              <Form.Option defaultValue>Bed Rooms</Form.Option>
              <Form.Option value="1">1</Form.Option>
              <Form.Option value="2">2</Form.Option>
              <Form.Option value="3">3</Form.Option>
              <Form.Option value="4">4</Form.Option>
              <Form.Option value="5">5</Form.Option>
            </Form.Select>
          </Form.FormGroup>
          {/* <Form.FormGroup>
            <Form.Span>
              Price range: Rs {priceFormat(+priceRange)} to Rs{" "}
              {priceFormat(maxPrice)}
            </Form.Span>
            <Form.RangeInput
              type="range"
              min={minPrice}
              max={maxPrice}
              value={priceRange}
              onChange={({ target: { value } }) => priceFilter(value)}
            />
          </Form.FormGroup> */}
          <Form.FormGroup>
            <Form.Input type="text" placeholder="Search Term" onChange={(e) => {
              setSearch(e.target.value)
            }} />
          </Form.FormGroup>
          <Form.FormGroup onClick={search}>
            <Form.SubmitInput type="submit" value="Search" />
          </Form.FormGroup>
        </Form>
        <Toaster toastOptions={{
          className: '',
          style: {
            padding: '16px',
            color: 'red',
          },
        }} />
      </FormWrapper.Content>
    </FormWrapper>
  );
};

export default AdvancedSearchContainer;
