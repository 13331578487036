import {
  GET_AGENTS,
  GET_FEATURED_AGENTS,
  GET_AGENT,
  ADD_AGENT
} from "../constants/agentsConstants";

import agents from "../../dev-data/agents";
import { URL } from "../constants/mainConstant";
import swal from 'sweetalert';
import Axios from "axios";

export const getFeaturedAgents = () => (dispatch) => {
  const featuredAgents = agents
    .filter((agent) => agent.featured === true)
    .slice(0, 3);
  dispatch({ type: GET_FEATURED_AGENTS, payload: featuredAgents });
};

export const getAgentList = () => async (dispatch) => {
  try {
    const { data } = await Axios.get(URL + `api/agents`);
    dispatch({ type: GET_AGENTS, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};

export const addAgents = (details) => async (dispatch) => {
  try {
    const { data } = await Axios.post(URL + `api/add-agent`, details, {
      headers: {
        'accept': 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data`,
      }
    });
    console.log("data after post request", data);
    swal("", `${data.message}`, "success");
    dispatch({ type: ADD_AGENT, payload: data });

  } catch (error) {
    console.log(error.message);
  }
};

export const getAgent = (id) => async (dispatch) => {
  //console.log("id in action", id);
  try {
    const { data } = await Axios.get(URL + `api/agent/${id}`);
    dispatch({ type: GET_AGENT, payload: data });
  } catch (error) {
    console.log(error.message);
  }
};
// export const getAgent = (id) => (dispatch) => {
//   const singleAgent = agents.find((agent) => agent.id === +id);

//   dispatch({ type: GET_AGENT, payload: singleAgent });
// };

