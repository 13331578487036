import {
  LOGIN_USER,
  LOGOUT_USER,
} from "../constants/loginConstants";

export const loginReducer = (state = { user: {}, message: null }, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { user: action.payload };
    case LOGOUT_USER:
      return { user: action.payload }
    default:
      return state;
  }
};