import {
  LOGIN_USER,
  LOGOUT_USER,
} from "../constants/loginConstants";
import swal from 'sweetalert';

import Axios from "axios";
import { URL } from "../constants/mainConstant";

export const loginUser = (user) => async (dispatch) => {
  // console.log("user in LOGIN action", user);
  try {
    const { data } = await Axios.post(URL + `users/signin`, user);
    localStorage.setItem("userData", JSON.stringify(data));
    dispatch({ type: LOGIN_USER, payload: data });
  } catch (error) {
    swal("", `${error.message}`, "error");
    console.log(error.message);
  }
};

export const logoutUser = (user) => (dispatch) => {
  localStorage.removeItem("userData");
  dispatch({ type: LOGOUT_USER, payload: null })
}