import React, { Fragment, useEffect, useState } from "react";
import { HeaderContainer, FooterContainer } from "../containers";
import { Login, Form } from "../components";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/actions/loginAction";
import { useHistory } from "react-router-dom";

const Loginn = () => {

  const history = useHistory();

  const user = useSelector((state) => state.userData);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(loginUser({
      email: email,
      password: password,
      // loggedIn: true.
    }));
  };

  useEffect(() => {
    if (user.user !== null) {
      history.push("/add-listing")
    }
  }, [user.user])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [HeaderContainer]);

  return (
    <Fragment>
      <HeaderContainer bg="false" />
      <Login onSubmit={(e) => handleSubmit(e)} >
        <Login.Container>
          <Login.Content>
            <Login.Header>
              <Login.Title>Login</Login.Title>
            </Login.Header>
            <Login.InnerContent>
              <Form>
                <Form.FormGroup>
                  <Form.Label>Email</Form.Label>
                  <Form.Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </Form.FormGroup>
                <Form.FormGroup>
                  <Form.Label>Password</Form.Label>
                  <Form.Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </Form.FormGroup>
                <Form.FormGroup>
                  <Form.SubmitInput type="submit" value="Login" />
                </Form.FormGroup>
              </Form>
            </Login.InnerContent>
            <Login.Footer>
              {/* <Login.Text>
                <Login.Anchor to="/forgot-password">
                  Forgot Password ?
                </Login.Anchor>
              </Login.Text> */}
              {/* <Login.Text>
                Don't have an Account ?{" "}
                <Login.Anchor to="/signup">Sign Up</Login.Anchor>
              </Login.Text> */}
            </Login.Footer>
          </Login.Content>
        </Login.Container>
      </Login>
      <FooterContainer />
    </Fragment>
  );
};

export default Loginn;
